import {
  GET_LIST,
  GET_DIVISION_ESTATE_COUNT,
  GET_MAP_ESTATE_LIST,
  GET_MAP_SEARCH_RESULT,
} from "./actionTypes";
import { get, post } from "@/axios";

const defaultState = {
  list: [],
  imgLoad: false,
  showCooperate: false,
  mapDivisionCountList: [],
  mapEstateList: [],
  mapSearchList: [],
  cooperateMsg: null,
  eaeCtr: [],
  cityDa: [],
  queryDet: {},
  querySta: [],
  getRec: [],
  getGood1: [],
  getGood2: [],
  getGood3: [],
};
export default (state = defaultState, action) => {
  //   reducer只能接受state，不能改变state
  if (action.type === GET_LIST) {
    let newState = JSON.parse(JSON.stringify(state));
    newState.list = action.data.data.list;
    return newState;
  }
  if (action.type === "ImgLoad") {
    let newState = JSON.parse(JSON.stringify(state));
    newState.imgLoad = action.data;
    return newState;
  }
  if (action.type === "openCooperate") {
    let newState = JSON.parse(JSON.stringify(state));
    newState.showCooperate = action.data;
    return newState;
  }
  if (action.type === GET_DIVISION_ESTATE_COUNT) {
    let newState = JSON.parse(JSON.stringify(state));
    newState.mapDivisionCountList = action.data;
    return newState;
  }
  if (action.type === GET_MAP_ESTATE_LIST) {
    let newState = JSON.parse(JSON.stringify(state));
    newState.mapEstateList = action.data;
    return newState;
  }
  if (action.type === GET_MAP_SEARCH_RESULT) {
    let newState = JSON.parse(JSON.stringify(state));
    newState.mapSearchList = action.data;
    return newState;
  }
  if (action.type === "cooperateMsg") {
    let newState = JSON.parse(JSON.stringify(state));
    newState.cooperateMsg = action.data;
    return newState;
  }
  if (action.type === "getEstate") {
    let newState = JSON.parse(JSON.stringify(state));
    newState[action.data.type] = action.data.data;
    return newState;
  }
  if (action.type === "getCity") {
    let newState = JSON.parse(JSON.stringify(state));
    newState.cityDa = action.data;
    return newState;
  }
  switch (action.type) {
    case "queryDet":
    case "querySta":
    case "getRec":
    case "getGood1":
    case "getGood2":
    case "getGood3":
      return { ...state, [action.type]: action.data };
    default:
      break;
  }
  return state;
};
