import "./index.scss";
import Menu from "@c/Menu";
import Foot from "@c/Foot";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import store from "@/redux";
import { useLocation, useNavigate } from "react-router-dom";
import { queryDet, querySta, getRecFun } from "@r/actionCreators";
import Talk from "@c/Talk";
import Cooperate from "@c/Cooperate";
import { csyData, findWYType } from "@co/js";
import {
  getCoorName,
  getFeeType,
  getNetSpStr,
  payMode,
  stationType,
  csyDataVal,
} from "@co/js";
import arrLeft from "@i/grey_prev_arrow.svg";
import arrRig from "@i/grey_next_arrow.svg";
import noImg from "@i/no-img.svg";
import litNoImg from "@i/little-img.svg";
import uniNum from "@i/uniNum.svg";
import perNum from "@i/peoNum.svg";

function EstDet(props) {
  const [param, setParam] = useState({});
  const { id } = useLocation().state || 0;
  const { detDat, detStaP, showCooperate, getRec } = props;
  const [detData, setDetData] = useState(detDat);
  const [imgData, setImgData] = useState({
    leftInd: 0,
    index: 0,
    src: detData?.pics && detData.pics[0]?.image,
  });
  const [limg, setLimg] = useState({
    index0: 0,
  });
  const [detSta, setDetSta] = useState(detStaP);
  const [wyType, setWYType] = useState(1);
  let navigate = useNavigate();

  useEffect(() => {
    setDetData(detDat);
    setImgData({ ...imgData, src: detDat?.pics && detDat.pics[0]?.image });
  }, [detDat]);

  useEffect(() => {
    setDetSta(detStaP);
  }, [detStaP]);

  useEffect(() => {
    setWYType(detDat.type);
    store.dispatch(getRecFun({ pageNum: 1, pageSize: 6, type: wyType }));
  }, [detDat.type]);

  useEffect(() => {
    store.dispatch(queryDet(id));
    store.dispatch(querySta(id));
  }, []);

  // console.log(getRec);

  const getSN = () => {
    let s = detSta;
    let sns = s.serialNumber;
    if (sns && sns.length > 0) {
      let arr = [];
      for (let i = 0; i < sns.length; i++) {
        arr.push(
          <span key={i} className="station-item-sn-item">
            {sns[i] + (sns.length - 1 === i ? "" : "、")}
          </span>
        );
      }
      return <div className="station-item-sns-div">{arr}</div>;
    } else {
      return "/";
    }
  };

  const getCoorStr = (type, data) => {
    let s = data;
    let name = s.coordinateName;
    let coor = s.coordinate;
    if (coor && coor.length > 0) {
      for (let i of coor) {
        if (i.name === name) {
          if (type === 1) {
            return i.longitude;
          } else {
            return i.latitude;
          }
        }
      }
    } else if (this.props.pageType === 1) {
      if (type === 1) {
        return coor.longitude;
      } else {
        return coor.latitude;
      }
    } else {
      return "/";
    }
  };

  const getStrFromInt = (arr, type) => {
    let res = "";
    if (arr && arr.length > 0) {
      for (let i = 0; i < arr.length; i++) {
        let str = "";
        if (type === 1) {
          str = getBaseStationAppType(arr[i]);
        } else if (type === 2) {
          str = getEnergyStationAppType(arr[i]);
        } else if (type === 3) {
          str = getRetailStationAppType(arr[i]);
        }
        if (i === arr.length - 1) {
          res = res + str;
        } else {
          res = res + str + "/";
        }
      }
    } else {
      res = "/";
    }
    return res;
  };

  const getBaseStationAppType = (type) => {
    if (type === 1) {
      return "楼站";
    } else if (type === 2) {
      return "塔站";
    } else if (type === 3) {
      return "飞箱";
    } else if (type === 4) {
      return "小基站";
    } else {
      return "其他";
    }
  };

  const getEnergyStationAppType = (type) => {
    if (type === 1) {
      return "换电柜";
    } else if (type === 2) {
      return "快递柜";
    } else if (type === 3) {
      return "充电桩";
    } else if (type === 4) {
      return "充电柜";
    } else {
      return "其他";
    }
  };

  const getRetailStationAppType = (type) => {
    if (type === 1) {
      return "无人售货柜";
    } else if (type === 2) {
      return "快递柜";
    }
  };

  return (
    <div className="estdet">
      <Menu />
      <div className="estdetBody">
        <div className="edbTop">
          <div className="edbtImg">
            <div className="edbtBig">
              <img src={imgData.src} alt="" />
            </div>
            <div className="edbtLit">
              {detData?.pics?.length > 3 && (
                <div
                  className="edbtlLeft"
                  onClick={() => {
                    setImgData({
                      ...imgData,
                      leftInd:
                        imgData.leftInd < 0
                          ? imgData.leftInd + 1
                          : imgData.leftInd,
                    });
                  }}
                >
                  <img src={require("@i/grey-left.png")} alt="" />
                </div>
              )}
              <div
                className="edbtlCen"
                style={{ marginLeft: imgData.leftInd * 210 + "px" }}
              >
                {detData?.pics?.map((x, y) => (
                  <img
                    className={`${imgData.index === y ? "act" : ""}`}
                    src={x.image}
                    key={y}
                    onClick={() => {
                      setImgData({ ...imgData, src: x.image, index: y });
                    }}
                    alt=""
                  />
                ))}
              </div>
              {detData?.pics?.length > 3 && (
                <div
                  className="edbtlRig"
                  onClick={() => {
                    setImgData({
                      ...imgData,
                      leftInd:
                        -(detDat.pics.length - 1) < imgData.leftInd
                          ? imgData.leftInd - 1
                          : imgData.leftInd,
                    });
                  }}
                >
                  <img src={require("@i/grey-right.png")} />
                </div>
              )}
            </div>
          </div>
          <div className="edbtInfo">
            <h5>{detData.name}</h5>
            <div className="edbtiType">
              <span>{findWYType(csyData, 7)}</span>
            </div>
            <div className="edbtiAdr">
              <img src={require("@i/adr.png")} alt="" />
              <span>{detData.address}</span>
            </div>
            <div className="edbtiNum">
              <div>
                <div>{detData.building || "--"}</div>
                <div>楼宇数量</div>
              </div>
              <div>
                <div>{detData.family || "--"}</div>
                <div>住户数量</div>
              </div>
              <div>
                <div>{detData.carPark || "--"}</div>
                <div>车位数量</div>
              </div>
              <div>
                <div>{detData.elevatorCount || "--"}</div>
                <div>电梯数量</div>
              </div>
            </div>
            <div className="edbtDet">
              <div>
                <label>物管名称</label>
                <span>{detData.propertyManagerName || "--"}</span>
              </div>
              <div>
                <label>产权单位</label>
                <span>{detData.owner || "--"}</span>
              </div>
              <div>
                <label>开发商</label>
                <span>{detData.developer || "--"}</span>
              </div>
              <div>
                <label>带宽状况</label>
                <span>{getNetSpStr(detData.netSp) || "--"}</span>
              </div>
              <div>
                <label>建成时间</label>
                <span>{detData.buildTime || "--"}</span>
              </div>
            </div>
            <div className="edbtMet">
              <div
                onClick={() =>
                  store.dispatch({ type: "openCooperate", data: true })
                }
              >
                <img src={require("@i/place.png")} alt="" />
                立即投放
              </div>
              <div>
                <img src={require("@i/tel.png")} alt="" />
                400-051-6152
              </div>
            </div>
          </div>
        </div>
        {detSta.length > 0 && (
          <div className="edbCen">
            <div className="edbHead">
              <h1>现有项目</h1>
            </div>
            <div className="edbcBody">
              {detSta?.map((x, y) => (
                <div className="edbcbItem" key={y}>
                  <div className="edbcImg">
                    <img
                      className="edbci1"
                      src={arrLeft}
                      onClick={() => {
                        setLimg({
                          ...limg,
                          ["index" + y]:
                            limg["index" + y] <= 0 ? 0 : limg["index" + y] - 1,
                        });
                      }}
                      alt=""
                    />
                    <img
                      className="edbci2"
                      src={arrRig}
                      onClick={() => {
                        setLimg({
                          ...limg,
                          ["index" + y]:
                            limg["index" + y] >= x.pics.length - 1
                              ? x.pics.length - 1
                              : (limg["index" + y] || 0) + 1,
                        });
                      }}
                      alt=""
                    />
                    <div className="edbciNum">
                      {(limg["index" + y] || 0) + 1}/{x.pics.length || 0}
                    </div>
                    {x.pics[limg["index" + y] || 0]?.image ? (
                      <img
                        className="edbcBig"
                        src={x.pics[limg["index" + y] || 0]?.image}
                        alt=""
                      />
                    ) : (
                      <img src={noImg} alt="" />
                    )}
                  </div>
                  <div className="edbcCon">
                    <h5>{x.name}</h5>
                    <div className="edbccType">
                      <span className="edbccj">{stationType[x.type]}</span>
                      {x.status === 2 ? (
                        <span className="edbcc1">运行中</span>
                      ) : (
                        <span className="edbcc2">未起租</span>
                      )}
                    </div>
                    {x.type === "base" && (
                      <div className="station-item-info-div">
                        <div className="station-item-info-half">
                          <div className="station-item-info-half-content-div">
                            <span className="title">坐标类型</span>
                            <span className="content">
                              {getCoorName(x.coordinateName)}
                            </span>
                          </div>
                          <div className="station-item-info-half-content-div">
                            <span className="title">经度坐标</span>
                            <span className="content">{getCoorStr(1, x)}</span>
                          </div>
                          <div className="station-item-info-half-content-div">
                            <span className="title">纬度坐标</span>
                            <span className="content">{getCoorStr(2, x)}</span>
                          </div>
                          <div className="station-item-info-half-content-div">
                            <span className="title">设备类型</span>
                            <span className="content">
                              {getStrFromInt(x.apparatusType, 1)}
                            </span>
                          </div>
                          <div className="station-item-info-half-content-div">
                            <span className="title">设备数量</span>
                            <span className="content">
                              {x.apparatusCount ? x.apparatusCount : "/"}
                            </span>
                          </div>
                          <div className="station-item-info-half-content-div">
                            <span className="title">占地面积</span>
                            <span className="content">
                              {x.groundArea ? x.groundArea + "㎡" : "/"}
                            </span>
                          </div>
                        </div>
                        <div className="station-item-info-half">
                          <div className="station-item-info-half-content-div">
                            <span className="title">站点高度</span>
                            <span className="content">
                              {x.height ? x.height + "m" : "/"}
                            </span>
                          </div>
                          <div className="station-item-info-half-content-div">
                            <span className="title">站点描述</span>
                            <span className="content">{x.desc || "/"}</span>
                          </div>
                        </div>
                      </div>
                    )}
                    {x.type === "energy" && (
                      <div className="station-item-info-div">
                        <div className="station-item-info-inndiv">
                          <div className="station-item-info-half">
                            <div className="station-item-info-half-content-div">
                              <span className="title">坐标类型</span>
                              <span className="content">
                                {getCoorName(x.coordinateName)}
                              </span>
                            </div>
                            <div className="station-item-info-half-content-div">
                              <span className="title">所属区域</span>
                              <span className="content">
                                {x.divisionName ? x.divisionName : "/"}
                              </span>
                            </div>
                            <div className="station-item-info-half-content-div">
                              <span className="title">电费形式</span>
                              <span className="content">
                                {getFeeType(x.feeType)}
                              </span>
                            </div>
                          </div>
                          <div className="station-item-info-half">
                            <div className="station-item-info-half-content-div">
                              <span className="title">经度坐标</span>
                              <span className="content">
                                {getCoorStr(1, x)}
                              </span>
                            </div>
                            <div className="station-item-info-half-content-div">
                              <span className="title">设备类型</span>
                              <span className="content">
                                {getStrFromInt(x.apparatusType, 2)}
                              </span>
                            </div>
                            <div className="station-item-info-half-content-div">
                              <span className="title">占地面积</span>
                              <span className="content">
                                {x.groundArea ? x.groundArea + "㎡" : "/"}
                              </span>
                            </div>
                          </div>
                          <div className="station-item-info-half">
                            <div className="station-item-info-half-content-div">
                              <span className="title">纬度坐标</span>
                              <span className="content">
                                {getCoorStr(2, x)}
                              </span>
                            </div>
                            <div className="station-item-info-half-content-div">
                              <span className="title">设备数量</span>
                              <span className="content">
                                {x.apparatusCount ? x.apparatusCount : "/"}
                              </span>
                            </div>
                            <div className="station-item-info-half-content-div">
                              <span className="title">站点高度</span>
                              <span className="content">
                                {x.height ? x.height + "m" : "/"}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="station-item-info-all">
                          <div className="station-item-info-all-content-div">
                            <span className="title">设备SN</span>
                            {getSN()}
                          </div>
                          <div className="station-item-info-all-content-div">
                            <span className="title">站点描述</span>
                            <span className="content">{x.desc || "/"}</span>
                          </div>
                        </div>
                      </div>
                    )}
                    {x.type === "retail" && (
                      <div className="station-item-info-div">
                        <div className="station-item-info-inndiv">
                          <div className="station-item-info-half">
                            <div className="station-item-info-half-content-div">
                              <span className="title">坐标类型</span>
                              <span className="content">
                                {getCoorName(x.coordinateName)}
                              </span>
                            </div>
                            <div className="station-item-info-half-content-div">
                              <span className="title">所属区域</span>
                              <span className="content">
                                {x.divisionName ? x.divisionName : "/"}
                              </span>
                            </div>
                            <div className="station-item-info-half-content-div">
                              <span className="title">付费形式</span>
                              <span className="content">
                                {payMode[x.payMode] || "/"}
                              </span>
                            </div>
                          </div>
                          <div className="station-item-info-half">
                            <div className="station-item-info-half-content-div">
                              <span className="title">经度坐标</span>
                              <span className="content">
                                {getCoorStr(1, x)}
                              </span>
                            </div>
                            <div className="station-item-info-half-content-div">
                              <span className="title">设备类型</span>
                              <span className="content">
                                {getStrFromInt(x.apparatusType, 3)}
                              </span>
                            </div>
                            <div className="station-item-info-half-content-div">
                              <span className="title">场地面积</span>
                              <span className="content">
                                {x.groundArea ? x.groundArea + "㎡" : "/"}
                              </span>
                            </div>
                          </div>
                          <div className="station-item-info-half">
                            <div className="station-item-info-half-content-div">
                              <span className="title">纬度坐标</span>
                              <span className="content">
                                {getCoorStr(2, x)}
                              </span>
                            </div>
                            <div className="station-item-info-half-content-div">
                              <span className="title">设备数量</span>
                              <span className="content">
                                {x.apparatusCount ? x.apparatusCount : "/"}
                              </span>
                            </div>
                            <div className="station-item-info-half-content-div">
                              <span className="title">客流量</span>
                              <span className="content">
                                {x.customerFlow
                                  ? x.customerFlow + "人/天"
                                  : "/"}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="station-item-info-all">
                          <div className="station-item-info-all-content-div">
                            <span className="title">站点承重</span>
                            {x.groundLoad ? x.groundLoad + "kg/㎡" : "/"}
                          </div>
                          <div className="station-item-info-all-content-div">
                            <span className="title">站点描述</span>
                            <span className="content">{x.desc || "/"}</span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        <div className="edbBot">
          <div className="edbHead">
            <h1>为您推荐</h1>
          </div>
          <div className="edbBBody">
            {getRec?.data?.map((x, y) => (
              <div
                key={y}
                className="ppybc-item"
                onClick={() => {
                  navigate("/estdet", {
                    state: { id: x.estateId },
                  });
                  window.location.href = "/estdet";
                }}
              >
                <div
                  className={`ppybci-left ${
                    x.imageUrl && x.imageUrl[0] ? "" : "ppybcil-lnIBox"
                  }`}
                >
                  <img
                    className={`ppybcil-img`}
                    src={(x.imageUrl && x.imageUrl[0]) || litNoImg}
                    alt=""
                  />
                  {csyDataVal[x.type] && (
                    <div className="ppybcil-tip">{csyDataVal[x.type]}</div>
                  )}
                </div>
                <div className="ppybci-right">
                  <h5>{x.name || "--"}</h5>
                  <div>
                    <div>
                      <div className="ppybcir-per">
                        <img src={uniNum} alt="" />
                        <span>
                          楼宇：{x.building ? x.building + "栋" : "--"}
                        </span>
                      </div>
                      <div className="ppybcir-hou">
                        <img src={perNum} alt="" />
                        <span>住户：{x.family ? x.family + "人" : "--"}</span>
                      </div>
                      <div className="ppybcir-adr">
                        <img src={require("@i/adr.png")} alt="" />
                        <span>地址：{x.address ? x.address + "人" : "--"}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Foot />
      {process.env.REACT_APP_PRO && <Talk />}
      <div className="cooperateWrap">{showCooperate && <Cooperate type={2}/>}</div>
    </div>
  );
}

const stateToProps = (state) => {
  return {
    detDat: state.queryDet,
    detStaP: state.querySta,
    getRec: state.getRec,
    showCooperate: state.showCooperate,
  };
};

export default connect(stateToProps)(EstDet);
