export const contactReg = (da, cb) => {
  if (!da) {
    cb("请输入联系方式！");
    return false;
  }
  const pat1 = /^1\d{10}$/;
  const pat2 = /^0\d{2,3}-?\d{7,8}$/;
  if (!pat1.test(da) && !pat2.test(da)) {
    cb("请输入正确的联系方式!");
    return false;
  } else {
    cb("");
    return true;
  }
};

export const csyDataVal = {
  1: "住宅",
  2: "商业",
  4: "产业园",
  5: "公众",
  6: "其他",
};

export const stationType = {
  base: "通信",
  energy: "新能源",
  retail: "新零售",
};

// export const payMode = {
//   1: "月付",
//   2: "季付",
//   3: "年付",
// };

export const siteType = {
  energy: "新能源",
  retail: "新零售",
};

export const csyData = [
  {
    industryId: 0,
    name: "全部",
    children: [],
  },
  {
    industryId: 1,
    name: "住宅",
    children: [
      {
        industryId: 0,
        name: "全部",
      },
      {
        industryId: 7,
        name: "住宅",
      },
      {
        industryId: 8,
        name: "公寓",
      },
      {
        industryId: 9,
        name: "别墅",
      },
    ],
  },
  {
    industryId: 2,
    name: "商业",
    children: [
      {
        industryId: 0,
        name: "全部",
      },
      {
        industryId: 10,
        name: "购物广场",
      },
      {
        industryId: 11,
        name: "写字楼",
      },
      {
        industryId: 12,
        name: "酒店",
      },
    ],
  },
  {
    industryId: 4,
    name: "产业园",
    children: [
      {
        industryId: 0,
        name: "全部",
      },
      {
        industryId: 13,
        name: "工厂",
      },
      {
        industryId: 14,
        name: "物流园",
      },
      {
        industryId: 15,
        name: "办公园区",
      },
    ],
  },
  {
    industryId: 5,
    name: "公众",
    children: [
      {
        industryId: 0,
        name: "全部",
      },
      {
        industryId: 16,
        name: "医院",
      },
      {
        industryId: 17,
        name: "学校",
      },
      {
        industryId: 18,
        name: "景区（公园，景区）",
      },
      {
        industryId: 19,
        name: "车站（高铁，机场，地铁，汽车站）",
      },
    ],
  },
  {
    industryId: 6,
    name: "其它",
    children: [
      {
        industryId: 20, //原33
        name: "其它",
      },
    ],
  },
];

export const simCsyData = [
  {
    industryId: 1,
    name: "住宅",
    children: [
      {
        industryId: 1,
        name: "全部",
      },
      {
        industryId: 7,
        name: "住宅",
      },
      {
        industryId: 8,
        name: "公寓",
      },
      {
        industryId: 9,
        name: "别墅",
      },
    ],
  },
  {
    industryId: 2,
    name: "商业",
    children: [
      {
        industryId: 2,
        name: "全部",
      },
      {
        industryId: 10,
        name: "购物广场",
      },
      {
        industryId: 11,
        name: "写字楼",
      },
      {
        industryId: 12,
        name: "酒店",
      },
    ],
  },
  {
    industryId: 4,
    name: "产业园",
    children: [
      {
        industryId: 4,
        name: "全部",
      },
      {
        industryId: 13,
        name: "工厂",
      },
      {
        industryId: 14,
        name: "物流园",
      },
      {
        industryId: 15,
        name: "办公园区",
      },
    ],
  },
  {
    industryId: 5,
    name: "公众",
    children: [
      {
        industryId: 5,
        name: "全部",
      },
      {
        industryId: 16,
        name: "医院",
      },
      {
        industryId: 17,
        name: "学校",
      },
      {
        industryId: 18,
        name: "景区（公园，景区）",
      },
      {
        industryId: 19,
        name: "车站（高铁，机场，地铁，汽车站）",
      },
    ],
  },
  {
    industryId: 6,
    name: "其它",
    children: [
      {
        industryId: 20, //原33
        name: "其它",
      },
    ],
  },
];

export const findWYType = (d, c) => {
  let v = "";
  function findType(a) {
    a &&
      a.length > 0 &&
      a.forEach((x) => {
        if (x.industryId === c) {
          v = x.name;
          return;
        } else {
          findType(x.children);
        }
      });
  }
  findType(d);
  return v;
};

export const siteAllType = {
  base: "通信",
  energy: "新能源",
  retail: "新零售",
};

export const stationTypeFun = (d) => {
  let a = "";
  d &&
    d.forEach((x, i) => {
      a = a + siteAllType[x] + (i === d.length - 1 ? "" : "、");
    });
  return a;
};
export const siteTypeFun = (d) => {
  let a = "--";
  csyData.forEach((x) => {
    if (x.industryId === d) {
      a = x.name;
    } else {
      x.children.forEach((y) => {
        if (y.industryId === d) {
          a = x.name;
        }
      });
    }
  });
  return a;
};

export const payMode = {
  1: "月付",
  2: "季付",
  3: "半年付",
  4: "年付",
};
export const eleMode = { 1: "动态", 2: "固定" };

export const enSArea = {
  1: "10以下",
  2: "11-50",
  3: "51-100",
  4: "101-150",
  5: "151-200",
  6: "201-500",
};

export const enArea = {
  1: "10m²以下",
  2: "11-50m²",
  3: "51-100m²",
  4: "101-150m²",
  5: "151-200m²",
  6: "201-500m²",
};

export const enFArea = {
  0: "全部",
  1: "10m²以下",
  2: "11-50m²",
  3: "51-100m²",
  4: "101-150m²",
  5: "151-200m²",
  6: "201-500m²",
};

export const custFlow = {
  1: "100以下",
  2: "101-500",
  3: "501-1000",
  4: "1001-2000",
  5: "2001-5000",
  6: "5000以上",
};

export const getNetSpStr = (arr) => {
  let nets = ["", "移动", "联通", "电信", "歌华", "其他"];
  if (arr && arr.length > 0) {
    let str = "";
    for (let i = 0; i < arr.length; i++) {
      str += nets[arr[i]] + ",";
    }
    str = str.slice(0, str.lastIndexOf(","));
    return str;
  } else {
    return "--";
  }
};

export const getCoorName = (name) => {
  if (name === "baidu") {
    return "百度";
  } else if (name === "google") {
    return "谷歌";
  } else if (name === "tencent") {
    return "腾讯";
  } else {
    return "/";
  }
};

export const getFeeType = (type) => {
  if (type) {
    if (type === 1) {
      return "预付费";
    } else if (type === 2) {
      return "后付费";
    } else {
      return "/";
    }
  } else {
    return "/";
  }
};
