import React, { useEffect, useState } from "react";
import "./index.scss";
import { connect } from "react-redux";
import store from "@/redux";
import { ADD_ITEM, CHANGE_INPUT } from "@/redux/actionTypes";
import { message } from "top-msg";
import { contactReg } from "@co/js";
import { cooperateMsg } from "@r/actionCreators";
import Spin from "@c/Spin";

import {
  changeInputAction,
  addItemAction,
  deleteItemAction,
  getTodoList,
} from "@/redux/actionCreators";

function Talk(props) {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [requ, setRequ] = useState("");
  const [bigBox, setBigBox] = useState(false);
  const [load, setLoad] = useState(false);

  useEffect(() => {
    // const action = getTodoList();
    // store.dispatch(action);
  }, []);

  const submit = () => {
    let flag = contactReg(phone, (a) => {
      a && message.error(a);
    });
    if (!flag) return;
    if (!requ) {
      message.error("请输入需求信息！");
      return;
    }
    const action = cooperateMsg({
      gender: 1,
      name,
      mobile: phone,
      note: requ,
    });
    setLoad(true);
    store.dispatch(action);
  };

  useEffect(() => {
    if (props.cooperateMsg === 2) {
      message.success("提交成功！");
      setLoad(null);
      setName("");
      setPhone("");
      setRequ("");
      store.dispatch({ type: "cooperateMsg", data: 1 });
    }
  }, [props.cooperateMsg]);

  return (
    <div className="leaveMsg">
      <div className={`box ${bigBox ? "littleBox" : "bigBox"}`}>
        <div className="litWrap">
          <img
            src={require("@i/doubleArrow.png")}
            onClick={() => {
              setBigBox(!bigBox);
            }}
          />
          <span>合作留言</span>
        </div>
        <Spin loading={load} />
        <div className="bigWrap">
          <img
            src={require("@i/custormerService.png")}
            onClick={() => {
              setBigBox(!bigBox);
            }}
            className="custormerImg"
          />
          <div>
            <div>热线电话</div>
            <div>400-051-6152</div>
          </div>
          <div>
            <div>
              <span>姓名</span>
              <input
                placeholder="请输入您的姓名"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </div>
            <div>
              <span>电话</span>
              <input
                placeholder="请输入您的电话"
                value={phone}
                onChange={(e) => {
                  setPhone(e.target.value);
                }}
                onBlur={() => {
                  contactReg(phone, (a) => {
                    a && message.warn(a);
                  });
                }}
              />
            </div>
          </div>
          <div>
            <span>需求</span>
            <textarea
              placeholder="请输入您的需求"
              maxLength={100}
              value={requ}
              onChange={(e) => {
                setRequ(e.target.value);
              }}
            />
            <button onClick={submit}>提交</button>
          </div>
          <img
            src={require("@i/doubleArrow.png")}
            onClick={() => {
              setBigBox(!bigBox);
            }}
          />
        </div>
      </div>
    </div>
  );
}

// export default LeaveMsg;

// const stateToProps = (state) => {
//   return {
//     inputValue: state.inputValue,
//     list: state.list,
//   };
// };
const stateToProps = (state) => {
  return {
    cooperateMsg: state.cooperateMsg,
  };
};
// const dispatchToProps = (dispatch) => {
//   return {
//     inputChange(e) {
//       let action = {
//         type: CHANGE_INPUT,
//         value: e.target.value,
//       };
//       dispatch(action);
//     },
//     clickBtn() {
//       let action = { type: ADD_ITEM };
//       dispatch(action);
//     },
//     deleteItem(index) {
//       const action = deleteItemAction(index);
//       store.dispatch(action);
//     },
//   };
// };

export default connect(stateToProps, null)(Talk);
