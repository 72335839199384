
import * as CryptoJS from "crypto-js";
import {
  CHANGE_INPUT,
  ADD_ITEM,
  DELET_ITEM,
  GET_LIST,
  GET_DIVISION_ESTATE_COUNT,
  GET_MAP_ESTATE_LIST,
  GET_MAP_SEARCH_RESULT,
} from "./actionTypes";
import { get, post } from "@/axios";
import qeyStr from "querystring";
let url = process.env.REACT_APP_URL;
// url = "https://ai.aiyunxuan.com/";
// url = "https://dev.aiyunxuan.com/";


export const changeInputAction = (value) => ({
  type: CHANGE_INPUT,
  value,
});

export const addItemAction = () => ({
  type: ADD_ITEM,
});

export const deleteItemAction = (index) => ({
  type: DELET_ITEM,
  index,
});

export const getListAction = (data) => ({
  type: GET_LIST,
  data,
});

export const getEstateCountAction = (data) => ({
  type: GET_DIVISION_ESTATE_COUNT,
  data,
});

export const getEstateMapAction = (data) => ({
  type: GET_MAP_ESTATE_LIST,
  data,
});

export const getMapSearchAction = (data) => ({
  type: GET_MAP_SEARCH_RESULT,
  data,
});

export const getTodoList = () => {
  return (dispatch) => {
    get(
      "https://mock.mengxuegu.com/mock/61a9c941c140524f189210d8/getList/getList"
    ).then((res) => {
      const data = res.data;
      const action = getListAction(data);
      dispatch(action);
    });
  };
};

export const getDivisionEstateCount = () => {
  return (dispatch) => {
    get(url + "offical/division/estate/count").then((res) => {
      let data = res.data.data;
      const action = getEstateCountAction(data);
      dispatch(action);
    });
  };
};

export const getEstateMap = (lLng, lLat, rLng, rLat, name) => {
  return (dispatch) => {
    get(
      url +
        "offical/estate/map?lb.longitude=" +
        lLng +
        "&lb.latitude=" +
        lLat +
        "&rt.longitude=" +
        rLng +
        "&rt.latitude=" +
        rLat +
        "&coordName=" +
        name
    ).then((res) => {
      let data = res.data.data;
      const action = getEstateMapAction(data);
      dispatch(action);
    });
  };
};

export const mapSearch = (key) => {
  return (dispatch) => {
    get(url + "offical/estate/search?keyWord=" + key).then((res) => {
      let data = res.data.data;
      const action = getMapSearchAction(data);
      dispatch(action);
    });
  };
};

export const cooperateMsg = (params, data) => {
  // console.log(11,params,encrypt(JSON.stringify(params)),decrypt(encrypt(JSON.stringify(params))))
  return (dispatch) => {
    post(
      // `${url}offical/locateOrder?terminalType=Offical&userId=&sessionStr=`,
      // 'https://test-agent.aiyunxuan.com/api/official/locateOrder',
      "https://agentapi.aiyunxuan.com/api/official/locateOrder",
      // "http://192.168.0.101:9801/api/official/locateOrder",
      // encrypt(JSON.stringify(params)),
      params,
      data
    ).then((res) => {
      const data = res.data;
      // console.log(33,data,params)
      if (data.code === 0) {
        dispatch({
          type: "cooperateMsg",
          data: 2,
        });
      }
    });
  };
};

export const getEstate = (param, type = "") => {
  return (dispatch) => {
    //url
    get(`${url}webhome/estate/queryData?${qeyStr.stringify(param)}`).then(
      (res) => {
        const data = res.data;
        if (data.code === 0) {
          dispatch({
            type: "getEstate",
            data: { data: data.data, type },
          });
        }
      }
    );
  };
};

export const getRecFun = (param) => {
  return (dispatch) => {
    get(`${url}webhome/estate/queryData?${qeyStr.stringify(param)}`).then(
      (res) => {
        const data = res.data;
        if (data.code === 0) {
          dispatch({
            type: "getRec",
            data: { data: data.data.records },
          });
        }
      }
    );
  };
};

export const getCity = () => {
  return (dispatch) => {
    //url
    get(`${url}offical/division/query?type=estate`).then((res) => {
      const data = res.data;
      if (data.code === 0) {
        dispatch({
          type: "getCity",
          data: data.data,
        });
      }
    });
  };
};

export const queryDet = (a) => {
  return (dispatch) => {
    get(`${url}offical/estate/detail?estateId=${a}`).then((res) => {
      const data = res.data;
      if (data.code === 0) {
        dispatch({
          type: "queryDet",
          data: data.data,
        });
      }
    });
  };
};

export const querySta = (a) => {
  return (dispatch) => {
    get(`${url}offical/estate/station?estateId=${a}`).then((res) => {
      const data = res.data;
      if (data.code === 0) {
        dispatch({
          type: "querySta",
          data: data.data,
        });
      }
    });
  };
};

export const getGood = (a, b) => {
  return (dispatch) => {
    get(`${url}webhome/estate/queryData?${qeyStr.stringify(a)}`).then((res) => {
      const data = res.data;
      if (data.code === 0) {
        dispatch({
          type: "getGood" + b,
          data: data.data.records,
        });
      }
    });
  };
};

// export const cooperateMsg1 = (params, data) => {
//   post(
//     `${url}offical/locateOrder?terminalType=Offical&userId=&sessionStr=`,
//     params,
//     data
//   ).then((res) => {
//     const data = res.data;
//     console.log(33,res)
//     return data
//   });
// };


const secretKey = "mrx1ibxyqbn6xmtd";
function encrypt(word) {
  let key = CryptoJS.enc.Utf8.parse(secretKey);
  let srcs = CryptoJS.enc.Utf8.parse(word);
  let encrypted = CryptoJS.AES.encrypt(srcs, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });
  return encrypted.toString();
}

function decrypt(word) {
  var key = CryptoJS.enc.Utf8.parse(secretKey);
  var decrypt = CryptoJS.AES.decrypt(word, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });
  return decrypt.toString(CryptoJS.enc.Utf8);
}
