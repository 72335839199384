import React, { useEffect } from "react";
// import { cDDet } from "../dict";
import Menu from "@c/Menu";
import Foot from "@c/Foot";
import Star from "@c/Star";
import "./detail.scss";
import dict from "./dict.json";

function EnergyDet() {
  const { pathname } = window.location;
  const id = pathname.slice(pathname.lastIndexOf("/") + 1);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const data = dict[id - 1];

  // const data = cDDet[id];

  return (
    <div className={"edet edet" + id + ""}>
      <Menu />
      <div className={"edet-box"}>
        <div className="head">
          <img src={require("@i/champion.png")} />
          <div className="title-box">
            <div className="first">{data.btitle}</div>
            <div className="second">{data.secondary}</div>
          </div>
        </div>
        <div className="body">
          <div className="title-box">
            <div className="first">{data.stitle}</div>
            {/* <div className="second">钾电池电动车/电瓶车十大品牌排行榜</div> */}
          </div>
          <div className="body-box">
            {data.content.map((x, y) => (
              <div className="body-item" key={y}>
                <div>
                  <span className={"number number" + (y+1) + ""}>{y + 1}</span>
                  <b>{x.name}</b>
                </div>
                <div>
                  <Star num={x.star} />
                  <span>品牌指数：</span>
                  <span>{x.index.toFixed(1)}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Foot />
    </div>
  );
}

export default EnergyDet;
