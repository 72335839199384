import React from "react";
import { cDDet } from "../dict";
import Menu from "@c/Menu";
import Foot from "@c/Foot";
import "./detail.scss";
import { useParams, useSearchParams } from "react-router-dom";

class NewDet extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    const { pathname } = window.location;
    const id = pathname.slice(pathname.lastIndexOf("/") + 1);
    const data = cDDet[id];

    return (
      <div className="cdet">
        <Menu />
        <div className="cdet-box">
          <div className="cdetb-wrap">
            <div className="cdetbw-head">
              <h1>{data.title}</h1>
              <div>
                <span>{data.from}</span>
                <span>{data.time}</span>
              </div>
            </div>
            <div className="cdetbw-body">
              {data.img.map((x, y) => (
                <img className={x.cls} key={y} src={x.img} alt="" />
              ))}
            </div>
          </div>
        </div>
        <Foot />
      </div>
    );
  }
}

export default NewDet;
