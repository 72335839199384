import React from "react";
import Menu from "@c/Menu";
import Foot from "@c/Foot";
import Talk from "@c/Talk";
import { conArr } from "./dict";
import { useNavigate } from "react-router-dom";
import Image from "react-lazy-load-content";
import "./index.scss";

function Energy() {
  const navigate = useNavigate();
  return (
    <div className="eey">
      <Menu />
      <div className="eey-head">
        <img className="eey-head-img" src={require("@i/eey-bg.png")} />
      </div>
      <div className="eey-body">
        <div className="eey-item">
          <h1 className="eey-headTxt">新能源品牌排行榜</h1>
          <div className="eey-phb">
            <div className="eey-phb-box">
              <img src={require("@i/phb1.png")} />
              <div
                className="more"
                onClick={() => {
                  navigate("det/1");
                }}
              ></div>
            </div>
            <div className="eey-phb-box">
              <img src={require("@i/phb2.png")} />
              <div
                className="more"
                onClick={() => {
                  navigate("det/2");
                }}
              ></div>
            </div>
            <div className="eey-phb-box">
              <img src={require("@i/phb3.png")} />
              <div
                className="more"
                onClick={() => {
                  navigate("det/3");
                }}
              ></div>
            </div>
            <div className="eey-phb-box">
              <img src={require("@i/phb4.png")} />
              <div
                className="more"
                onClick={() => {
                  navigate("det/4");
                }}
              ></div>
            </div>
          </div>
        </div>
        <div className="eey-item">
          <h1 className="eey-headTxt">新能源厂商</h1>
          <div className="eey-com-body">
            {conArr.map((x) => {
              return (
                <div
                  key={x.id}
                  style={{
                    height: x.style.height + "px",
                    width: x.style.width + "px",
                  }}
                  onClick={() => {
                    let url = x.url,
                      nu = "";
                    if (url.indexOf("://") > -1) {
                      nu = url;
                      window.open(nu);
                    } else if (url != "") {
                      nu = "http://" + url;
                      window.open(nu);
                    } else if (url == "") {
                      window.open(
                        "https://www.baidu.com/s?ie=UTF-8&wd=" + x.name
                      );
                    }
                  }}
                  className="eey-item-con"
                >
                  <Image src={x.img} placeholde="" />
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <Foot />
      {process.env.REACT_APP_PRO && <Talk />}
    </div>
  );
}

export default Energy;
