export const conArr = [
  {
    id: 1,
    name: "上海玖行能源科技有限公司",
    url: "",
    style: { width: 673, height: 554 },
    img: require("@i/energy/1-1.png"),
  },
  {
    id: 2,
    name: "阳光电源股份有限公司",
    url: "https://www.sungrowpower.com/?bd_vid=11601560284508150942",
    style: { width: 388, height: 554 },
    img: require("@i/energy/1-2.png"),
  },
  {
    id: 3,
    name: "万帮数字能源股份有限公司",
    url: "http://www.starcharge.com",
    style: { width: 359, height: 485 },
    img: require("@i/energy/2-1.png"),
  },
  {
    id: 4,
    name: "上海鼎充新能源技术有限公司",
    url: "https://dingchong123.qiyeku.com/index.html",
    style: { width: 359, height: 505 },
    img: require("@i/energy/2-2.png"),
  },
  {
    id: 5,
    name: "苏州瀚川智能科技股份有限公司",
    url: "https://www.harmontronics.com/",
    style: { width: 359, height: 505 },
    img: require("@i/energy/2-3.png"),
  },
  {
    id: 6,
    name: "赛摩智能科技集团股份有限公司",
    url: "https://www.saimo.cn/",
    style: { width: 673, height: 554 },
    img: require("@i/energy/3-1.png"),
  },
  {
    id: 7,
    name: "意科能源中国",
    url: "www.enelx-emobility.cn",
    style: { width: 388, height: 554 },
    img: require("@i/energy/3-2.png"),
  },
  {
    id: 8,
    name: "四川蔚宇电气有限责任公司",
    url: "http://www.scwydq.cn/",
    style: { width: 359, height: 509 },
    img: require("@i/energy/4-1.png"),
  },
  {
    id: 9,
    name: "安徽全速智能科技有限公司",
    url: "http://chargesup.cn/#/index",
    style: { width: 359, height: 509 },
    img: require("@i/energy/4-2.png"),
  },
  {
    id: 10,
    name: "宙晖(北京)新能源科技有限公司",
    url: "https://www.dazzlesky.com/",
    style: { width: 359, height: 509 },
    img: require("@i/energy/4-3.png"),
  },
  {
    id: 11,
    name: "深圳市科华恒盛科技有限公司",
    url: "https://www.kehuasz.com/",
    style: { width: 672, height: 554 },
    img: require("@i/energy/5-1.png"),
  },
  {
    id: 12,
    name: "广州万城万充新能源科技有限公司",
    url: "https://www.gdmcmc.cn/",
    style: { width: 388, height: 554 },
    img: require("@i/energy/5-2.png"),
  },
  {
    id: 13,
    name: "深圳ABB电动交通科技有限公司",
    url: "https://new.abb.com/cn",
    style: { width: 359, height: 505 },
    img: require("@i/energy/6-1.png"),
  },
  {
    id: 14,
    name: "徐州杰恒回转支承有限公司",
    url: "www.jiehon.com",
    style: { width: 359, height: 505 },
    img: require("@i/energy/6-2.png"),
  },
  {
    id: 15,
    name: "北京双杰电气股份有限公司",
    url: "",
    style: { width: 359, height: 505 },
    img: require("@i/energy/6-3.png"),
  },
  {
    id: 16,
    name: "深圳市电王科技有限公司",
    url: "http://www.techone.com.cn/cn/",
    style: { width: 672, height: 571 },
    img: require("@i/energy/7-1.png"),
  },
  {
    id: 17,
    name: "武汉烽火富华电气有限责任公司",
    url: "",
    style: { width: 388, height: 571 },
    img: require("@i/energy/7-2.png"),
  },
  {
    id: 18,
    name: "深圳市优优绿能股份有限公司",
    url: "",
    style: { width: 359, height: 525 },
    img: require("@i/energy/8-1.png"),
  },
  {
    id: 19,
    name: "公牛集团股份有限公司",
    url: "",
    style: { width: 359, height: 525 },
    img: require("@i/energy/8-2.png"),
  },
  {
    id: 20,
    name: "株洲市众普森技术有限公司",
    url: "",
    style: { width: 359, height: 525 },
    img: require("@i/energy/8-3.png"),
  },
  {
    id: 21,
    name: "温州丰宝客电子有限公司",
    url: "",
    style: { width: 672, height: 554 },
    img: require("@i/energy/9-1.png"),
  },
  {
    id: 22,
    name: "博格华纳新能源（襄阳）有限公司",
    url: "",
    style: { width: 388, height: 554 },
    img: require("@i/energy/9-2.png"),
  },
  {
    id: 23,
    name: "新亚东方电能科技有限公司",
    url: "",
    style: { width: 359, height: 508 },
    img: require("@i/energy/10-1.png"),
  },
  {
    id: 24,
    name: "深圳科士达科技股份有限公司",
    url: "",
    style: { width: 359, height: 510 },
    img: require("@i/energy/10-2.png"),
  },
  {
    id: 25,
    name: "青岛科捷机器人有限公司",
    url: "",
    style: { width: 359, height: 510 },
    img: require("@i/energy/10-3.png"),
  },
  {
    id: 26,
    name: "小蜂新能源汽车充电技术（苏州）有限公司",
    url: "",
    style: { width: 672, height: 537 },
    img: require("@i/energy/11-1.png"),
  },
  {
    id: 27,
    name: "特来电新能源股份有限公司",
    url: "",
    style: { width: 389, height: 537 },
    img: require("@i/energy/11-2.png"),
  },
  {
    id: 28,
    name: "广东鸿蓝智能科技有限公司",
    url: "",
    style: { width: 359, height: 547 },
    img: require("@i/energy/12-1.png"),
  },
  {
    id: 29,
    name: "国联智慧能源交通技术创新中心(苏州)有限公司",
    url: "",
    style: { width: 359, height: 547 },
    img: require("@i/energy/12-2.png"),
  },
  {
    id: 30,
    name: "上海捷焕智能科技有限公司",
    url: "",
    style: { width: 359, height: 547 },
    img: require("@i/energy/12-3.png"),
  },
  {
    id: 31,
    name: "绿能慧充数字技术有限公司",
    url: "",
    style: { width: 673, height: 537 },
    img: require("@i/energy/13-1.png"),
  },
  {
    id: 32,
    name: "金茂智慧交通科技有限公司",
    url: "",
    style: { width: 389, height: 537 },
    img: require("@i/energy/13-2.png"),
  },
  {
    id: 33,
    name: "常安集团有限公司",
    url: "www.changangroup.com",
    style: { width: 359, height: 595 },
    img: require("@i/energy/14-1.png"),
  },
  {
    id: 34,
    name: "能链智电",
    url: "www.enaas.com",
    style: { width: 359, height: 595 },
    img: require("@i/energy/14-2.png"),
  },
  {
    id: 35,
    name: "深圳市盛弘电气股份有限公司",
    url: "https://www.sinexcel.com/",
    style: { width: 359, height: 595 },
    img: require("@i/energy/14-3.png"),
  },
  {
    id: 36,
    name: "杭州闪充聚能新能源有限公司",
    url: "",
    style: { width: 672, height: 567 },
    img: require("@i/energy/15-1.png"),
  },
  {
    id: 37,
    name: "博众精工科技股份有限公司",
    url: "",
    style: { width: 388, height: 567 },
    img: require("@i/energy/15-2.png"),
  },
  {
    id: 38,
    name: "深圳市润诚达电力科技有限公司",
    url: "http://www.sz-rcd.com/",
    style: { width: 359, height: 583 },
    img: require("@i/energy/16-1.png"),
  },
  {
    id: 39,
    name: "北京小桔新能源汽车科技有限公司",
    url: "https://www.didiglobal.com/automobile-service/energy",
    style: { width: 359, height: 583 },
    img: require("@i/energy/16-2.png"),
  },
  {
    id: 40,
    name: "上海能辉科技股份有限公司",
    url: "http://www.nhet.com.cn/",
    style: { width: 359, height: 583 },
    img: require("@i/energy/16-3.png"),
  },
];
