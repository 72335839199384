import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Menu from "@c/Menu";
import Foot from "@c/Foot";
import Talk from "@c/Talk";
import Cooperate from "@c/Cooperate";
import SwiperCon from "@c/Swiper";
import { connect } from "react-redux";
import store from "@/redux";
import { getGood } from "@/redux/actionCreators";

import "./index.scss";
import { numdynamic } from "numdynamic";
// import Image from "react-animate-lazy-load-image";
import Image from "react-lazy-load-content";
// import message from "@c/Message";
import { message } from "top-msg";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/core";
import {
  changeInputAction,
  addItemAction,
  deleteItemAction,
  getTodoList,
} from "@/redux/actionCreators";
import { list } from "postcss";

function Home(props) {
  const [cityNum, setCityNum] = useState(0);
  const [estateNum, setEstateNum] = useState(0);
  const [agentnum, setAgentNum] = useState(0);
  const [stationNum, setStationNum] = useState(0);
  const [brandNum, setBrandNum] = useState(0);
  const [tabInd, setTabInd] = useState(0);
  const [tabSInd, setTabSInd] = useState(0);
  const [flag, setFlag] = useState(false);
  const wrapRef = useRef(null);
  const stickyRef = useRef();
  const timer = useRef();
  const timerSite = useRef();
  const tabLeft = { 0: 330, 1: 476, 2: 626, 3: 766 };
  const tabSLeft = { 0: 17, 1: 109, 2: 199 };

  let navigate = useNavigate();
  const { showCooperate, getGood1, getGood2, getGood3 } = props;

  // console.log( getGood1, getGood2, getGood3)

  useEffect(() => {
    store.getState().imgLoad && isDynimic();
  }, [store.getState().imgLoad]);

  useEffect(() => {
    if (flag) {
      let a = numdynamic({ time: 3500, num: 12 }, function (num) {
        setCityNum(num);
      });
      let b = numdynamic({ time: 3500, num: 3000 }, function (num) {
        setEstateNum(num);
      });
      let c = numdynamic({ time: 3500, num: 1000 }, function (num) {
        setAgentNum(num);
      });
      let d = numdynamic({ time: 3500, num: 5000 }, function (num) {
        setStationNum(num);
      });
      let e = numdynamic({ time: 3500, num: 100 }, function (num) {
        setBrandNum(num);
      });
      // console.log(a);
      return () => {
        clearInterval(a);
        clearInterval(b);
        clearInterval(c);
        clearInterval(d);
        clearInterval(e);
      };
    }
    // return () => clearInterval(a);
  }, [flag]);

  // useEffect(() => {
  //   window.addEventListener("scroll", isDynimic);
  //   return () => window.removeEventListener("scroll", isDynimic);
  // }, []);

  const isDynimic = () => {
    const { top } = stickyRef.current?.getBoundingClientRect() || 0;
    const { innerHeight } = window;
    if (top < innerHeight - 115) {
      setFlag(true);
    }
  };

  useEffect(() => {
    // rotation();
    rotationSite();
    window.addEventListener("scroll", isDynimic);
    store.dispatch(getGood({ pageNum: 1, pageSize: 3, queryType: 1 }, 1));
    store.dispatch(getGood({ pageNum: 1, pageSize: 3, queryType: 2 }, 2));
    store.dispatch(getGood({ pageNum: 1, pageSize: 3, queryType: 3 }, 3));
    return () => {
      clearInterval(timer.current);
      clearInterval(timerSite.current);
      window.removeEventListener("scroll", isDynimic);
    };
  }, []);

  // const rotation = () => {
  //   let num = tabInd;
  //   timer.current = setInterval(() => {
  //     num = num + 1;
  //     if (num >= 2) {
  //       num = 0;
  //       setTabInd(0);
  //     } else {
  //       setTabInd(num);
  //     }
  //   }, 3500);
  // };
  const rotationSite = () => {
    let num = tabSInd;
    timerSite.current = setInterval(() => {
      num = num + 1;
      if (num >= 3) {
        num = 0;
        setTabSInd(0);
      } else {
        setTabSInd(num);
      }
    }, 3500);
  };

  return (
    <div ref={wrapRef} className="home">
      <Menu />
      <SwiperCon />
      <div className="dynNum">
        <div className="dynNumBox" ref={stickyRef}>
          <div>
            <div>{cityNum}</div>
            <div>城市覆盖数</div>
          </div>
          <div>
            <div>{estateNum}+</div>
            <div>物业上线数</div>
          </div>
          <div>
            <div>{agentnum}+</div>
            <div>经纪人数量</div>
          </div>
          <div>
            <div>{stationNum}+</div>
            <div>建成站点数</div>
          </div>
          <div>
            <div>{brandNum}+</div>
            <div>赋能品牌数</div>
          </div>
        </div>
      </div>

      <div className="solution">
        <div className="head">
          <h2>智慧设备投放解决方案</h2>
          {/* <div>提供优质场地资源 - 因地制宜 - 事半功倍</div> */}
        </div>
        <div className="body">
          <div className="tabHead">
            <div>
              <div
                className={`${tabInd === 0 ? "tabAct" : ""}`}
                onMouseEnter={() => {
                  setTabInd(0);
                  clearInterval(timer.current);
                }}
                // onMouseOut={() => rotation()}
              >
                新基建智慧设备
              </div>
              <div
                className={`${tabInd === 1 ? "tabAct" : ""}`}
                onMouseEnter={() => {
                  setTabInd(1);
                  clearInterval(timer.current);
                }}
                // onMouseOut={() => rotation()}
              >
                新零售智慧设备
              </div>
              <div
                className={`${tabInd === 2 ? "tabAct" : ""}`}
                onMouseEnter={() => {
                  setTabInd(2);
                  clearInterval(timer.current);
                }}
                // onMouseOut={() => rotation()}
              >
                新社区智慧设备
              </div>
              <div
                className={`${tabInd === 3 ? "tabAct" : ""}`}
                onMouseEnter={() => {
                  setTabInd(3);
                  clearInterval(timer.current);
                }}
                // onMouseOut={() => rotation()}
              >
                其它案场服务
              </div>
            </div>
            <div style={{ left: tabLeft[tabInd] + "px" }}></div>
          </div>
          <div className="content">
            <div className={`${tabInd === 0 ? "conAct" : "conHide"}`}>
              {/* <Image enter="show" src={require("@i/solution1.png")} /> */}
              <Swiper
                className="swiperSelf"
                spaceBetween={50}
                slidesPerView={1}
                modules={[Autoplay]}
                autoplay={{
                  delay: 3000, // 默认延迟3s播放下一张
                  stopOnLastSlide: false, // 播放到最后一张时停止：否
                  disableOnInteraction: false, // 用户操作轮播图后停止 ：是
                  pauseOnMouseEnter: true,
                }}
                loop
              >
                <SwiperSlide>
                  <img src={require("@i/solution1_1.png")} />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={require("@i/solution1_2.png")} />
                </SwiperSlide>
              </Swiper>
            </div>
            <div className={`${tabInd === 1 ? "conAct" : "conHide"}`}>
              <Image enter="show" src={require("@i/solution2.png")} />
            </div>
            <div className={`${tabInd === 2 ? "conAct" : "conHide"}`}>
              <Image enter="show" src={require("@i/solution3.png")} />
            </div>
            <div className={`${tabInd === 3 ? "conAct" : "conHide"}`}>
              <Image enter="show" src={require("@i/solution4.png")} />
            </div>
          </div>
        </div>
      </div>

      <div className="comprehensive">
        <div className="head">
          <h2>物业综合性服务</h2>
        </div>
        <div className="body">
          <div>
            <Image enter="show" src={require("@i/wyzhxfw.png")} placeholde="" />
          </div>
        </div>
      </div>

      <div className="characteristic">
        <div className="head">
          <h2>专题特色服务</h2>
        </div>
        <div className="body">
          <div>
            <Image
              enter="show"
              src={require("@i/characteristic.png")}
              placeholde=""
            />
          </div>
        </div>
      </div>

      <div className="site">
        <div className="head">
          <h2>优质场地资源</h2>
          <div>提供优质场地资源 - 因地制宜 - 事半功倍</div>
        </div>
        <div className="body">
          <div className="selectMap">
            <Image
              enter="show"
              src={require("@i/click_map.png")}
              placeholde=""
            />
            <div className="note">
              <div>地图选址</div>
              <div>Map site selection</div>
              <div
                onClick={() => {
                  window.open("/map", "_target");
                }}
              >
                查看详情
              </div>
            </div>
          </div>
          <div className="tabHead">
            <div>
              <div
                className={`${tabSInd === 0 ? "tabAct" : ""}`}
                onMouseEnter={() => {
                  setTabSInd(0);
                  clearInterval(timerSite.current);
                }}
                onMouseOut={() => rotationSite()}
              >
                写字楼
              </div>
              <div
                className={`${tabSInd === 1 ? "tabAct" : ""}`}
                onMouseEnter={() => {
                  setTabSInd(1);
                  clearInterval(timerSite.current);
                }}
                onMouseOut={() => rotationSite()}
              >
                优质社区
              </div>
              <div
                className={`${tabSInd === 2 ? "tabAct" : ""}`}
                onMouseEnter={() => {
                  setTabSInd(2);
                  clearInterval(timerSite.current);
                }}
                onMouseOut={() => rotationSite()}
              >
                产业园
              </div>
            </div>
            <div style={{ left: tabSLeft[tabSInd] + "px" }}></div>
          </div>
          <div className="content">
            <div className={`${tabSInd === 0 ? "conAct" : "conHide"}`}>
              {getGood1.map((x, y) => (
                <div
                  key={y}
                  onClick={() => {
                    navigate("/estdet", { state: { id: x.estateId } });
                  }}
                >
                  <Image enter="show" src={x.imageUrl && x.imageUrl[0]} />
                  <div>
                    <div>
                      <div>{x.name}</div>
                      <div></div>
                    </div>
                    <div>
                      <div>
                        <label>楼宇</label>
                        <span>{x.building || "-"}栋</span>
                      </div>
                      <div>
                        <label>住户</label>
                        <span>{x.family || "-"}户</span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className={`${tabSInd === 1 ? "conAct" : "conHide"}`}>
              {getGood2.map((x, y) => (
                <div
                  key={y}
                  onClick={() => {
                    navigate("/estdet", { state: { id: x.estateId } });
                  }}
                >
                  <Image enter="show" src={x?.imageUrl[0]} />
                  <div>
                    <div>
                      <div>{x.name}</div>
                      <div></div>
                    </div>
                    <div>
                      <div>
                        <label>楼宇</label>
                        <span>{x.building || "-"}栋</span>
                      </div>
                      <div>
                        <label>住户</label>
                        <span>{x.family || "-"}户</span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className={`${tabSInd === 2 ? "conAct" : "conHide"}`}>
              {getGood3.map((x, y) => (
                <div
                  key={y}
                  onClick={() => {
                    navigate("/estdet", { state: { id: x.estateId } });
                  }}
                >
                  <Image enter="show" src={x?.imageUrl[0]} />
                  <div>
                    <div>
                      <div>{x.name}</div>
                      <div></div>
                    </div>
                    <div>
                      <div>
                        <label>楼宇</label>
                        <span>{x.building || "-"}栋</span>
                      </div>
                      <div>
                        <label>住户</label>
                        <span>{x.family || "-"}户</span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="cooperation">
        <div className="head">
          <h2>合作品牌</h2>
        </div>
        <div className="bodyWrap">
          <div className="cooperation_bg">
            <div className="mask1"></div>
            <div className="mask2"></div>
          </div>
          <div className="body">
            <div className="item item1">
              <div className="title title1">目前已合作物业项目3000+</div>
              <Image
                enter="show"
                src={require("@i/brandLeft.png")}
                placeholde=""
              />
            </div>
            <div className="item item2">
              <div className="title title2">累计服务超100家头部企业</div>
              <Image
                enter="show"
                src={require("@i/brandRight.png")}
                placeholde=""
              />
            </div>
          </div>
        </div>
      </div>

      {/* <div className="choice">
        <div>
          <div>
            <div>
              <div>合作咨询</div>
              <div></div>
              <div>Cooperation consultation</div>
            </div>
            <div
              onClick={() =>
                store.dispatch({ type: "openCooperate", data: true })
              }
            >
              点击咨询
            </div>
          </div>
          <div>
            <Image
              enter="show"
              src={require("@i/cooperate.png")}
              placeholde=""
            />
          </div>
        </div>
        <div>
          <div>
            <div>
              <div>地图选址</div>
              <div></div>
              <div>Map location</div>
            </div>
            <div
              onClick={() => {
                // navigate("/map");
                window.open("/map", "_target");
              }}
            >
              点击选址
            </div>
          </div>
          <div>
            <Image enter="show" src={require("@i/selAdr.png")} placeholde="" />
          </div>
        </div>
      </div> */}

      {/* <div className="scheme">
        <div className="head">
          <h2>解决方案</h2>
        </div>
        <div className="body">
          <div>
            <div>
              <div className="imgBox">
                <Image enter="show" src={require("@i/hdgimg.png")} />
              </div>
              <div className="explain">
                <div>
                  <div>换电柜</div>
                  <div></div>
                  <div>power exchange cabinet</div>
                </div>
                <img
                  src={require("@i/arrowWhiteRight.png")}
                  onClick={() => navigate("/estate")}
                />
              </div>
            </div>
            <div>
              <div className="imgBox">
                <Image enter="show" src={require("@i/zxccdzimg.png")} />
              </div>
              <div className="explain">
                <div>
                  <div>
                    电动自行车
                    <br />
                    充电桩
                  </div>
                  <div></div>
                  <div>Electric bicycle charging pile</div>
                </div>
                <img
                  src={require("@i/arrowWhiteRight.png")}
                  onClick={() => navigate("/estate")}
                />
              </div>
            </div>
            <div className="schemeSpecial">
              <div className="imgBox">
                <Image enter="show" src={require("@i/qccdzimg.png")} />
              </div>
              <div className="explain explainLeft">
                <div>
                  <div>
                    电动汽车
                    <br />
                    充电桩
                  </div>
                  <div></div>
                  <div>electric car charger Electric pile</div>
                </div>
                <img
                  src={require("@i/arrowWhiteRight.png")}
                  onClick={() => navigate("/estate")}
                />
              </div>
            </div>
            <div className="schemeSpecial">
              <div className="imgBox">
                <Image enter="show" src={require("@i/jzxzimg.png")} />
              </div>
              <div className="explain explainLeft">
                <div>
                  <div>5G基站选址</div>
                  <div></div>
                  <div>5G Base station site selection</div>
                </div>
                <img
                  src={require("@i/arrowWhiteRight.png")}
                  onClick={() => navigate("/estate")}
                />
              </div>
            </div>
            <div>
              <div className="imgBox">
                <Image enter="show" src={require("@i/hdzimg.png")} />
              </div>
              <div className="explain">
                <div>
                  <div>换电站</div>
                  <div></div>
                  <div>swap station</div>
                </div>
                <img
                  src={require("@i/arrowWhiteRight.png")}
                  onClick={() => navigate("/estate")}
                />
              </div>
            </div>
            <div>
              <div className="imgBox">
                <Image enter="show" src={require("@i/lsbimg.png")} />
              </div>
              <div className="explain">
                <div>
                  <div>零售吧</div>
                  <div></div>
                  <div>retail bar</div>
                </div>
                <img
                  src={require("@i/arrowWhiteRight.png")}
                  onClick={() => navigate("/estate")}
                />
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* <div className="siteRes">
        <div className="head">
          <h2>优质场地资源</h2>
          <div>提供优质场地资源 - 因地制宜 - 事半功倍</div>
        </div>
        <div className="body">
          <div className="tabHead">
            <div>
              <div
                className={`${tabInd === 0 ? "tabAct" : ""}`}
                onMouseEnter={() => {
                  setTabInd(0);
                  clearInterval(timer.current);
                }}
                // onMouseOut={() => rotation()}
              >
                写字楼
              </div>
              <div
                className={`${tabInd === 1 ? "tabAct" : ""}`}
                onMouseEnter={() => {
                  setTabInd(1);
                  clearInterval(timer.current);
                }}
                // onMouseOut={() => rotation()}
              >
                优质社区
              </div>
              <div
                className={`${tabInd === 2 ? "tabAct" : ""}`}
                onMouseEnter={() => {
                  setTabInd(2);
                  clearInterval(timer.current);
                }}
                // onMouseOut={() => rotation()}
              >
                产业园
              </div>
            </div>
            <div style={{ left: tabLeft[tabInd] + "px" }}></div>
          </div>
          <div className="content">
            <div className={`${tabInd === 0 ? "conAct" : "conHide"}`}>
              {getGood1.map((x, y) => (
                <div
                  key={y}
                  onClick={() => {
                    navigate("/estdet", { state: { id: x.estateId } });
                  }}
                >
                  <Image enter="show" src={x.imageUrl && x.imageUrl[0]} />
                  <div>
                    <div>
                      <div>{x.name}</div>
                      <div></div>
                    </div>
                    <div>
                      <div>
                        <label>楼宇</label>
                        <span>{x.building || "-"}栋</span>
                      </div>
                      <div>
                        <label>住户</label>
                        <span>{x.family || "-"}户</span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className={`${tabInd === 1 ? "conAct" : "conHide"}`}>
              {getGood2.map((x, y) => (
                <div
                  key={y}
                  onClick={() => {
                    navigate("/estdet", { state: { id: x.estateId } });
                  }}
                >
                  <Image enter="show" src={x?.imageUrl[0]} />
                  <div>
                    <div>
                      <div>{x.name}</div>
                      <div></div>
                    </div>
                    <div>
                      <div>
                        <label>楼宇</label>
                        <span>{x.building || "-"}栋</span>
                      </div>
                      <div>
                        <label>住户</label>
                        <span>{x.family || "-"}户</span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className={`${tabInd === 2 ? "conAct" : "conHide"}`}>
              {getGood3.map((x, y) => (
                <div
                  key={y}
                  onClick={() => {
                    navigate("/estdet", { state: { id: x.estateId } });
                  }}
                >
                  <Image enter="show" src={x?.imageUrl[0]} />
                  <div>
                    <div>
                      <div>{x.name}</div>
                      <div></div>
                    </div>
                    <div>
                      <div>
                        <label>楼宇</label>
                        <span>{x.building || "-"}栋</span>
                      </div>
                      <div>
                        <label>住户</label>
                        <span>{x.family || "-"}户</span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div> */}
      {/* <div className="coopCase">
        <div className="head">
          <h2>合作案例</h2>
        </div>
        <div className="body"></div>
      </div> */}
      <div className="serPro">
        <div className="head">
          <h2>优质智能的选址服务流程</h2>
          <div>贯穿选址规划，智能选址，谈判合作，数据分析全流程</div>
        </div>
        <div className="body">
          <Image enter="show" src={require("@i/server_process.png")} />
        </div>
      </div>
      <Foot />
      {process.env.REACT_APP_PRO && <Talk />}
      <div className="cooperateWrap">
        {showCooperate && <Cooperate type={1} />}
      </div>
    </div>
  );
}

const stateToProps = (state) => {
  return {
    inputValue: state.inputValue,
    list: state.list,
    showCooperate: state.showCooperate,
    getGood1: state.getGood1,
    getGood2: state.getGood2,
    getGood3: state.getGood3,
  };
};

export default connect(stateToProps, null)(Home);
