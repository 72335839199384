import React from "react";
import comPro from "@i/comPro.png";
import jxgl from "@i/jxgl.png";
import Menu from "@c/Menu";
import Foot from "@c/Foot";
import Talk from "@c/Talk";
import advantage1 from "../../images/advantage1.png";
import advantage2 from "../../images/advantage2.png";
import advantage3 from "../../images/advantage3.png";
import advantage4 from "../../images/advantage4.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/core";
// import "swiper/css";

import "./index.scss";
// import {  } from "swiper";

class About extends React.Component {
  render() {
    return (
      <div className="aot">
        <Menu />
        <div className="aot-body">
          <div className="aotb-item">
            <h1 className="aot-headTxt">公司简介</h1>
            <p style={{ textAlign: 'left' }}>
              爱云选，致力于成为国内领先的公用空间智慧运营平台，为充换电、新零售柜、智能设备、新基建等运营商提供空间管理服务，业务涵盖空间选址、经营管理、佣金管理等。

              未来，爱云选将基于数字化、智能化、标准化的业务流程，为选址服务和公用空间综合运营提供新思路、新方案。
            </p>
            <img
              src={comPro}
              alt=""
              style={{ width: "100%", marginTop: "20px" }}
            />
          </div>
          <div className="aotb-item">
            <h1 className="aot-headTxt">资质专利</h1>
            <p>
              平台目前已经获得平台相关软著26个，拥有一个智慧设备支付的专利技术
              <br />
              已获得中关村高新技术企业证书, 国家高新技术企业认证, 创新型中小企业
              <br />
              2022年爱云选被评为“2022中国物业服务企业500强优选服务商品牌”
              <br />
              2022年爱云选被评为"2022亚太自助售货及智慧零售企业年度优秀奖”
            </p>

            <div className="swiperWrapZZ">
              <Swiper
                spaceBetween={50}
                slidesPerView={4}
                modules={[Autoplay, Navigation]}
                autoplay={{
                  delay: 3000, // 默认延迟3s播放下一张
                  stopOnLastSlide: false, // 播放到最后一张时停止：否
                  disableOnInteraction: false, // 用户操作轮播图后停止 ：是
                  pauseOnMouseEnter: true,
                }}
                loop
                navigation={{
                  nextEl: ".swiper-button-next",
                  prevEl: ".swiper-button-prev",
                }}
              >
                {[1, 2, 3, 4, 5, 6, 7].map((x) => (
                  <SwiperSlide key={x}>
                    <img src={require("@i/zz" + x + ".png")} />
                  </SwiperSlide>
                ))}

                {/* <SwiperSlide>
                  <img src={require("@i/zz2.png")} />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={require("@i/zz3.png")} />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={require("@i/zz4.png")} />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={require("@i/zz5.png")} />
                </SwiperSlide> */}
              </Swiper>
              <div className="swiper-button-prev">
                <img src={require("@i/array_left_grey.png")} />
              </div>
              <div className="swiper-button-next">
                <img src={require("@i/array_right_grey.png")} />
              </div>
            </div>
          </div>
          <div className="aotb-item aotb-itemBbox">
            <h1 className="aot-headTxt">爱云选优势</h1>
            <div className="aotbi-box">
              <img src={require("@i/advantage.png")} />
            </div>
          </div>
        </div>
        <Foot />
        {process.env.REACT_APP_PRO && <Talk />}
      </div>
    );
  }
}

export default About;
