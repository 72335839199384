import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import store from "@/redux";
import { contactReg } from "@co/js";
import "./index.scss";
import Spin from "@c/Spin";

import { cooperateMsg, cooperateMsg1 } from "@r/actionCreators";
import { message } from "top-msg";

function Cooperate(props) {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [requ, setRequ] = useState("");
  const [isPro, setPro] = useState(true);
  const errMsg = ["请您输入联系方式", "请输入正确的联系方式"];
  const [err, setErr] = useState("");
  const [load, setLoad] = useState(null);
  const submit = (p) => {
    if (p === 1) {
      let flag = contactReg(phone, (a) => {
        setErr(a);
      });
      if (!flag) return;
      if (!requ) {
        message.error("请输入需求信息！");
        return;
      }
      const action = cooperateMsg({
        gender: props.type,
        name,
        mobile: phone,
        note: requ,
      });
      setLoad(true);
      store.dispatch(action);
    } else if (p === 2) {
      close();
    }
  };
  const close = () => {
    store.dispatch({ type: "openCooperate", data: false });
    store.dispatch({ type: "cooperateMsg", data: 1 });
  };

  useEffect(() => {
    if (props.cooperateMsg === 2) {
      setLoad(null);
      setPro(!isPro);
    }
  }, [props.cooperateMsg]);

  return (
    <div className="cooperate">
      <div className={`box`}>
        <Spin loading={load} />
        <div className={`progress ${isPro ? "show" : "hide"}`}>
          <div className="bhead">
            <span>合作咨询</span>
            <img src={require("@i/close.png")} onClick={() => close()} />
          </div>
          <div className="name">
            <div>
              <label>姓名</label>
              <input
                placeholder="请输入您的姓名"
                maxLength={20}
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </div>
            <div></div>
          </div>
          <div className="phone">
            <div>
              <label>手机</label>
              <input
                placeholder="请输入您的手机"
                maxLength={20}
                value={phone}
                onChange={(e) => {
                  setPhone(e.target.value);
                }}
                onBlur={() => {
                  contactReg(phone, (a) => {
                    setErr(a);
                  });
                }}
              />
            </div>
            <div>{err}</div>
          </div>
          <div className="require">
            <div>
              <label>需求</label>
              <textarea
                placeholder="请输入您的需求"
                maxLength={500}
                value={requ}
                onChange={(e) => {
                  setRequ(e.target.value);
                }}
              />
            </div>
            <div></div>
          </div>
          <button className="upCon" onClick={() => submit(1)}>
            提交
          </button>
        </div>
        <div className={`done ${isPro ? "hide" : "show"}`}>
          <div className="bhead">
            <span>合作咨询</span>
            <img src={require("@i/close.png")} onClick={() => close()} />
          </div>
          <img src={require("@i/done.png")} />
          <p>
            您的需求已经提交成功，客服人员将在30分钟内与您联系，请您留意接听电话
          </p>
          <button className="upFin" onClick={() => submit(2)}>
            好的
          </button>
        </div>
      </div>
    </div>
  );
}

const stateToProps = (state) => {
  return {
    cooperateMsg: state.cooperateMsg,
  };
};

export default connect(stateToProps)(Cooperate);
