import "./index.scss";

function Star(prop) {
  const star1 = prop.num;
  const star0 = 5 - prop.num;
  const star0Arr = [],
    star1Arr = [];
  for (let i = 0; i < star0; i++) {
    star0Arr.push(i);
  }
  for (let i = 0; i < star1; i++) {
    star1Arr.push(i);
  }
  return (
    <div className="star">
      {star1Arr.map((x) => {
        return <img key={x} src={require("@i/star1.png")} />;
      })}
      {star0Arr.map((x) => {
        return <img key={x} src={require("@i/star0.png")} />;
      })}
    </div>
  );
}

export default Star;
