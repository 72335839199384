import React from "react";
import "./index.scss";

function Spin(props) {
  const { loading } = props;
  if (!loading) return "";
  return (
    <div className="spin">
      <div className="spinner">
        <div className="cube1"></div>
        <div className="cube2"></div>
      </div>
    </div>
  );
}

export default Spin;
