import "./index.scss";
import Menu from "@c/Menu";
import Foot from "@c/Foot";
import Talk from "@c/Talk";
import Pagination from "rc-pagination";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import store from "@/redux";
import { getEstate, getCity } from "@r/actionCreators";
import littleNoImg from "@i/littleNoImg.svg";
import adr from "@i/adr.png";
import uniNum from "@i/uniNum.svg";
import perNum from "@i/peoNum.svg";
import configuring from "@i/configuring.svg";
import triangle from "@i/triangle.svg";
import { csyData, csyDataVal } from "@co/js";
import blueClose from "@i/blue-close.svg";
import { useNavigate } from "react-router-dom";

function Estate(props) {
  const [param, setParam] = useState({
    pageNum: 1,
    pageSize: 20,
    queryValue: "",
    divisionCode: "110100",
    type: "",
  });
  const [list, setList] = useState([]);
  const [srhVal, setSrhVal] = useState("");
  const [showArea, setShowArea] = useState(false);
  const [showFArea, setShowFArea] = useState(false);
  const [countyData, setCountyData] = useState([]);
  const [areaKey, setAreaKey] = useState("110100");
  const [cityData, setCityData] = useState([]);
  const [actSta, setActSta] = useState({});
  // const [csyData, setCsyData] = useState([]);
  const [cliClsKey, setCliClsKey] = useState(0);
  const [clsdList, setClsdList] = useState([]);
  const [cliClsdKey, setCliClsdKey] = useState(0);
  const [cliClsdName, setCliClsdName] = useState("");
  const [areaName, setAreaName] = useState("");
  const [flag, setFlag] = useState(false);
  const [showWType, setShowWType] = useState(false);
  const [cliClsName, setCliClsName] = useState("");
  const [clsName, setClsName] = useState("");
  const { eaeCtr, cityDa } = props;
  const navigate = useNavigate();

  useEffect(() => {
    const action = getEstate(param, "eaeCtr");
    store.dispatch(action);
    const action1 = getCity();
    store.dispatch(action1);
  }, []);

  useEffect(() => {
    // const obj = { ...param, total: eaeCtr.total };
    setList(eaeCtr.records);
    // setParam(obj);
  }, [eaeCtr]);

  useEffect(() => {
    let a = {};
    let arr = cityDa.result || [];
    arr.forEach((x) => {
      if (x.tag === "b") {
        a = x.div[0].children[0];
      }
    });
    setCityData(cityDa);
    setCountyData(a);
  }, [flag]);

  useEffect(() => {
    if (JSON.stringify(cityDa) !== "[]") setFlag(true);
  }, [cityDa]);

  const onSearch = () => {
    // if (srhVal.trim() === "") return;
    const action = getEstate({ ...param, queryValue: srhVal }, "eaeCtr");
    store.dispatch(action);
  };

  const cliCity = (a, b, c, d) => {
    if (d === "hot") {
      let arr = cityData.result || [];
      arr.forEach((x) => {
        x.div.forEach((y) => {
          y.children.forEach((z) => {
            if (z.divisionCode === b.divisionCode) {
              b = z;
            }
          });
        });
      });
    }
    setCountyData(b);
    setActSta(c);
    setAreaKey(b.divisionCode);
    setShowFArea(true);
    setAreaName("全部");
    setParam({
      ...param,
      divisionCode: b.divisionCode,
      pageNum: 1,
    });
    // onSeaPro();
    // this.setState(
    //   {
    //     countyData: b,
    //     actSta: c,
    //     areaKey: b.divisionCode,
    //     showFArea: true,
    //     areaName: "全部",
    //     queryData: {
    //       ...this.state.queryData,
    //       divisionCode: b.divisionCode,
    //       pageNum: 1,
    //     },
    //   },
    //   () => {
    //     this.onSeaPro();
    //   }
    // );
  };
  const onSeaPro = (v) => {
    let data = v ? v : param;
    const action = getEstate(data, "eaeCtr");
    store.dispatch(action);
  };
  const clidCls = (a, b, c) => {
    // this.setState(
    //   {
    //     cliClsdKey: b,
    //     cliClsdName:
    //       c && c.indexOf("（") > -1 ? c.slice(0, c.indexOf("（")) : c,
    //     queryData: { ...this.state.queryData, type: b, pageNum: 1 },
    //   },
    //   () => this.onSeaPro()
    // );
    setCliClsdKey(b);
    setCliClsdName(c && c.indexOf("（") > -1 ? c.slice(0, c.indexOf("（")) : c);
    setShowWType(true);
    setParam({ ...param, type: b, pageNum: 1 });
    onSeaPro({ ...param, type: b, pageNum: 1 });
  };

  const cliArea = (a, b, c) => {
    // console.log(a, b, c);
    setAreaKey(b);
    setAreaName(c);
    setShowFArea(true);
    setParam({ ...param, divisionCode: b, pageNum: 1 });
    setShowArea(false);
    onSeaPro({ ...param, divisionCode: b, pageNum: 1 });
    // this.setState(
    //   {
    //     areaKey: b,
    //     areaName: c,
    //     showFArea: true,
    //     param: { ...param, divisionCode: b, pageNum: 1 },
    //     showArea: false,
    //   },
    //   () => this.onSeaPro()
    // );
  };

  const cliCls = (a, b) => {
    // console.log(a,b)
    setParam({ ...param, type: b.industryId || "", pageNum: 1 });
    setCliClsKey(b.industryId);
    onSeaPro({ ...param, type: b.industryId || "", pageNum: 1 });
    setShowWType(true);
    setClsName(b.name);
    // this.setState(
    //   {
    //     clsdList: b.children,
    //     cliClsKey: b.industryId,
    //     cliClsName: b.name,
    //     cliClsdName: "全部",
    //     showWType: b.industryId ? true : false,
    //     cliClsdKey: b.children[0] && b.children[0].industryId,
    //     queryData: {
    //       ...this.state.queryData,
    //       type: b.industryId || "",
    //       pageNum: 1,
    //     },
    //   },
    //   () => this.onSeaPro()
    // );
  };

  return (
    <div className="estate">
      <Menu />
      <div className="eaeBody">
        <div className="ppy-head">
          {/* <img className="ppyh-banner" src={banner} alt="" /> */}
          <div className="ppyb-box">
            <div className="ppyb-sh">
              <input
                className="ppybs-ser"
                placeholder="请输入物业名称进行搜索..."
                value={srhVal}
                onChange={(e) => setSrhVal(e.target.value)}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    onSearch();
                  }
                }}
              />
              <button className="ppybs-btn" onClick={onSearch}>
                搜索
              </button>
            </div>
            <div className="ppyb-filter">
              {showArea ? (
                <div className="allCity_box">
                  <div className="cur_area">
                    <h6 className="area-left" onChange={() => {}}>
                      <span>{countyData.name}</span>
                      <img
                        src={triangle}
                        alt=""
                        onClick={() => {
                          setShowArea(false);
                        }}
                      />
                    </h6>
                    <div className="area-right">
                      <span
                        className={`arear-item ${
                          areaKey === countyData.divisionCode
                            ? "areari-active"
                            : ""
                        }`}
                        onClick={(e) =>
                          cliArea(e, countyData.divisionCode, "全部")
                        }
                      >
                        全部
                      </span>
                      {countyData.children &&
                        countyData.children.map((x, y) => (
                          <span
                            key={x.divisionCode}
                            className={`arear-item ${
                              areaKey === x.divisionCode ? "areari-active" : ""
                            }`}
                            onClick={(e) => cliArea(e, x.divisionCode, x.name)}
                          >
                            {x.name}
                          </span>
                        ))}
                    </div>
                  </div>
                  <div className="hot_area">
                    <h6 className="area-left" onChange={() => {}}>
                      <span>热门城市</span>
                    </h6>
                    <div className="area-right">
                      {cityData.trend &&
                        cityData.trend.map((x, y) => (
                          <span
                            key={x.divisionCode}
                            className={`arear-item ${
                              actSta.fourth === x.divisionCode
                                ? "areari-active"
                                : ""
                            }`}
                            onClick={(e) =>
                              cliCity(e, x, { fourth: x.divisionCode }, "hot")
                            }
                          >
                            {x.name}
                          </span>
                        ))}
                    </div>
                  </div>
                  <div className="all-city">
                    <div className="all-city-inn">
                      {cityData &&
                        cityData.result &&
                        cityData.result.map((d, i) => {
                          return (
                            <div
                              key={i}
                              className={`all-city-inn-item ${
                                actSta.first == d.tag
                                  ? "all-city-inn-item-active"
                                  : ""
                              }`}
                            >
                              <div className="all-city-inn-item-left">
                                {d.tag.toUpperCase()}
                              </div>
                              <div
                                className={`all-city-inn-item-rig`} // ${i === 3 ? "all-city-inn-itemlong" : ""}
                              >
                                {d.div &&
                                  d.div.map((x, j) => (
                                    <div
                                      key={"a" + j}
                                      className={`all-city-inn-item-rig-item ${
                                        actSta.second === x.divisionCode
                                          ? "all-city-inn-item-rig-item-active"
                                          : ""
                                      }`}
                                    >
                                      <div className="allCity-iir-left">
                                        {x.name}
                                      </div>
                                      <div className="allCity-iir-rig">
                                        {x.children &&
                                          x.children.map((y, k) => (
                                            <span
                                              key={y.divisionCode}
                                              className={`arear-item ${
                                                actSta.third === y.divisionCode
                                                  ? "areari-active"
                                                  : ""
                                              }`}
                                              onClick={(e) =>
                                                cliCity(e, y, {
                                                  first: d.tag,
                                                  second: x.divisionCode,
                                                  third: y.divisionCode,
                                                })
                                              }
                                            >
                                              {y.name}
                                            </span>
                                          ))}
                                      </div>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              ) : null}
              <div className="ppybf-up">
                <h6 className="ppybfu-left" onChange={() => {}}>
                  <span>{countyData.name}</span>
                  <img
                    src={triangle}
                    alt=""
                    onClick={() => {
                      setShowArea(true);
                    }}
                  />
                </h6>
                <div className="ppybfu-right">
                  <span
                    className={`ppybfur-item ${
                      areaKey === countyData.divisionCode ? "ppybf-active" : ""
                    }`}
                    onClick={(e) => cliArea(e, countyData.divisionCode, "全部")}
                  >
                    全部
                  </span>
                  {countyData.children &&
                    countyData.children.map((x, y) => (
                      <span
                        key={x.divisionCode}
                        className={`ppybfur-item ${
                          areaKey === x.divisionCode ? "ppybf-active" : ""
                        }`}
                        onClick={(e) => cliArea(e, x.divisionCode, x.name)}
                      >
                        {x.name}
                      </span>
                    ))}
                </div>
              </div>
              <div className="ppybf-down">
                <h6 className="ppybfd-left">物业类型</h6>
                <div className="ppybfd-right">
                  <div>
                    {Object.keys(csyData).map((x, y) => (
                      <span
                        key={csyData[x].industryId}
                        className={`ppybfdr-item ${
                          cliClsKey === csyData[x].industryId
                            ? "ppybf-active"
                            : ""
                        }`}
                        onClick={(e) => cliCls(e, csyData[x])}
                      >
                        {csyData[x].name}
                      </span>
                    ))}
                  </div>
                  {/* <div>
                    {Object.keys(clsdList).map((x, y) => (
                      <span
                        key={clsdList[x].industryId}
                        className={`ppybfdr-item ${
                          cliClsdKey === clsdList[x].industryId
                            ? "ppybf-active"
                            : ""
                        }`}
                        onClick={(e) =>
                          clidCls(e, clsdList[x].industryId, clsdList[x].name)
                        }
                      >
                        {clsdList[x].name}
                      </span>
                    ))}
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ppyb-filSta">
          {showFArea && areaKey !== "110100" && areaKey !== "全部" ? (
            <div>
              <span>{countyData.name + "-" + areaName}</span>
              <img
                src={blueClose}
                alt=""
                onClick={() => {
                  // this.setState(
                  //   {
                  //     showFArea: false,
                  //     areaKey: countyData.divisionCode,
                  //     areaName: "全部",
                  //     queryData: {
                  //       ...this.state.queryData,
                  //       divisionCode: countyData.divisionCode,
                  //     },
                  //   },
                  //   () => {
                  //     this.onSeaPro();
                  //   }
                  // );
                  setShowFArea(false);
                  setAreaKey(countyData.divisionCode);
                  setAreaName("全部");
                  setParam({ ...param, divisionCode: countyData.divisionCode });
                  onSeaPro({ ...param, divisionCode: countyData.divisionCode });
                }}
              />
            </div>
          ) : null}
          {showWType ? (
            <div>
              {/* <span>{cliClsName + "-" + cliClsdName}</span> */}
              <span>{"物业-" + clsName}</span>
              <img
                src={blueClose}
                alt=""
                onClick={() => {
                  // this.setState(
                  //   {
                  //     showWType: false,
                  //     cliClsKey: 0,
                  //     clsdList: [],
                  //     queryData: {
                  //       ...this.state.queryData,
                  //       type: "",
                  //       pageNum: 1,
                  //     },
                  //   },
                  //   () => this.onSeaPro()
                  // );
                  setShowWType(false);
                  setCliClsKey(0);
                  setClsdList([]);
                  setParam({ ...param, type: "", pageNum: 1 });
                  onSeaPro({ ...param, type: "", pageNum: 1 });
                }}
              />
            </div>
          ) : null}
        </div>
        <div className="ppyb-con">
          {list && list.length > 0 ? (
            list.map((x, y) => (
              <div
                key={y}
                className="ppybc-item"
                onClick={() => {
                  navigate("/estdet", { state: { id: x.estateId } });
                }}
              >
                <div
                  className={`ppybci-left ${
                    x.imageUrl && x.imageUrl[0] ? "" : "ppybcil-lnIBox"
                  }`}
                >
                  <img
                    className={`ppybcil-img`}
                    src={(x.imageUrl && x.imageUrl[0]) || littleNoImg}
                    alt=""
                  />
                  {/* {x.area ? (
                    <div className="ppybcil-tips">
                      <img src={adr} alt="" />
                      <span>{x.area || "--"}</span>
                    </div>
                  ) : null} */}
                  {csyDataVal[x.type] && (
                    <div className="ppybcil-tip">{csyDataVal[x.type]}</div>
                  )}
                </div>
                <div className="ppybci-right">
                  <h5>{x.name || "--"}</h5>
                  <div>
                    <div>
                      <div className="ppybcir-per">
                        <img src={uniNum} alt="" />
                        <span>
                          楼宇：{x.building ? x.building + "栋" : "--"}
                        </span>
                      </div>
                      <div className="ppybcir-hou">
                        <img src={perNum} alt="" />
                        <span>住户：{x.family ? x.family + "人" : "--"}</span>
                      </div>
                      <div className="ppybcir-adr">
                        <img src={adr} alt="" />
                        <span>地址：{x.address ? x.address + "人" : "--"}</span>
                      </div>
                    </div>
                    {/* <button onClick={() => this.lookDet(x.estateId, x.type)}>
                      查看详情
                    </button> */}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="ppybc-conf">
              <img src={configuring} alt="" />
              <div>项目正在配置中…</div>
            </div>
          )}
        </div>
        {/* {console.log(eaeCtr)} */}
        <Pagination
          className="pagination"
          total={eaeCtr.total}
          pageSize={param.pageSize}
          current={param.pageNum}
          onChange={(e) => {
            setParam({ ...param, pageNum: e });
            onSeaPro({ ...param, pageNum: e });
          }}
          showTotal={() => "共" + eaeCtr.total + "条"}
          prevIcon={() => (
            <svg
              viewBox="64 64 896 896"
              focusable="false"
              data-icon="left"
              width="1em"
              height="1em"
              fill="currentColor"
              aria-hidden="true"
            >
              <path d="M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 000 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z"></path>
            </svg>
          )}
          nextIcon={() => (
            <svg
              viewBox="64 64 896 896"
              focusable="false"
              data-icon="right"
              width="1em"
              height="1em"
              fill="currentColor"
              aria-hidden="true"
            >
              <path d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z"></path>
            </svg>
          )}
          jumpPrevIcon={() => (
            <>
              <div>•••</div>
              <img src={require("@i/doubleRight.png")} />
            </>
          )}
          jumpNextIcon={() => (
            <>
              <div>•••</div>
              <img src={require("@i/doubleRight.png")} />
            </>
          )}
          hideOnSinglePage={true}
          showQuickJumper={true}
        />
      </div>
      {process.env.REACT_APP_PRO && <Talk />}
      <Foot />
    </div>
  );
}

const stateToProps = (state) => {
  return {
    eaeCtr: state.eaeCtr,
    cityDa: state.cityDa,
  };
};
export default connect(stateToProps, null)(Estate);
