import ayx from "@i/ayx.png";
import gxhdg from "@i/gxhdg.png";
import zdshj from "@i/zdshj.png";
import xls from "@i/xls.png";
import scztc from "@i/scztc.png";

import p1z1 from '@i/1-1.png'
import p1z2 from '@i/1-2.png'
import p1z3 from '@i/1-3.png'
import p1z4 from '@i/1-4.png'
import p2z1 from '@i/2-1.png'
import p2z2 from '@i/2-2.png'
import p2z3 from '@i/2-3.png'
import p2z4 from '@i/2-4.png'
import p2z5 from '@i/2-5.png'
import p2z6 from '@i/2-6.png'
import p2z7 from '@i/2-7.png'
import p3z1 from '@i/3-1.png'
import p3z2 from '@i/3-2.png'
import p3z3 from '@i/3-3.png'
import p3z4 from '@i/3-4.png'
import p3z5 from '@i/3-5.png'
import p3z6 from '@i/3-6.png'
import p3z7 from '@i/3-7.png'
import p3z8 from '@i/3-8.png'
import p4z1 from '@i/4-1.png'
import p4z2 from '@i/4-2.png'
import p4z3 from '@i/4-3.png'
import p4z4 from '@i/4-4.png'
import p4z5 from '@i/4-5.png'
import p4z6 from '@i/4-6.png'
import p4z7 from '@i/4-7.png'
import p4z8 from '@i/4-8.png'
import p5z1 from '@i/5-1.png'
import p5z2 from '@i/5-2.png'
import p5z3 from '@i/5-3.png'
import p5z4 from '@i/5-4.png'
import p5z5 from '@i/5-5.png'
import p5z6 from '@i/5-6.png'
import p5z7 from '@i/5-7.png'


export const cData = [
  {
    img: ayx,
    title: "新基建加速布局，“爱云选”点燃运营商发展新引擎",
    cont1:
      "新基建近来备受关注，其实这并不是一个新概念，早在2018年底召开的中央经济工作会议上就明确了“新型基础设施建设”的表述。",
    cont2:
      "近年来，中央不断深化推进对新基建的工作部署，2020年政府工作报告明确指出重点支持“两新一重”建设。新基建将起到承上启下的作用，既助力“十三五”规划顺利收官，又与“十四五”规划发展目标相契合。至此，新基建逐步发展为国家的新时期战略。",
    time: "2020-12-18",
    id: 1,
  },
  {
    img: gxhdg,
    title: "共享换电柜迎来千亿蓝海，爱云选助力运营商乘势而上",
    cont1:
      "随着“新基建”战略的逐步形成，新能源正成为能源消费市场的重要力量。电动自行车是新能源出行领域极为重要的组成部分。而面向电动自行车的共享换电柜也逐渐成为城市出行领域涌现的新物种。",
    cont2: "",
    time: "2020-12-25",
    id: 2,
  },
  {
    img: zdshj,
    title: "自动售货机行业竞争加剧，运营商如何快速get选址技能，锁定胜局？",
    cont1:
      "近年来，受新零售、无人零售等新概念的影响，自动售货机被更广泛的应用于包含零售业态在内的各种业态中，根据测算，2019年我国自动售货机行业市场规模约为285亿元，自助售货设备生产规模、设备保有量继续保持增长。 ",
    cont2: "",
    time: "2020-12-31",
    id: 3,
  },
  {
    img: xls,
    title: "爱云选架起关键桥梁，让新零售实现人货场最优匹配",
    cont1:
      "在近年开始的新零售浪潮中，无数企业和投资机构涌入无人经济的风口，随着大数据及人工智能等技术手段的革新和移动支付的普及，无人经济遍地开花。",
    cont2:
      "众所周知，新零售成功的重要一环是实现人、货、场三者之间的最优化匹配。无人设备以其多元化的经营模式、更小的面积和更高的坪效，与新零售发展的要求相契合。",
    time: "2021-01-08",
    id: 4,
  },
  {
    img: scztc,
    title: "蔬菜直通车难进小区？爱云选专业选址服务，为运营商开启绿色通道",
    cont1:
      "民以食为天，我们日常购置水果、蔬菜等食品，看似微不足道实则至关重要。在家门口买到放心菜、便宜菜，是许多居民的热切期望。但是随着城市建设的改造和发展，设施陈旧的农贸市场、菜市场逐步淘汰，这导致了菜市场数量不足、布局不合理，一定程度上加剧了城市居民买菜难、买菜贵。",
    cont2: "",
    time: "2021-01-15",
    id: 5,
  },
];


export const cDDet = {
    1:{
        title:'新基建加速布局，“爱云选”点燃运营商发展新引擎',
        from:'爱云选公众号',
        time:'2020-12-18',
        img:[{img:p1z1},{img:p1z2,cls:'cdetbwb-img'},{img:p1z3},{img:p1z4}]
    },
    2:{
        title:'共享换电柜迎来千亿蓝海，爱云选助力运营商乘势而上',
        from:'爱云选公众号',
        time:'2020-12-25',
        img:[{img:p2z1},{img:p2z2},{img:p2z3,cls:'cdetbwb-img'},{img:p2z4},{img:p2z5},{img:p2z6},{img:p2z7}]
    },
    3:{
        title:'自动售货机行业竞争加剧，运营商如何快速get选址技能，锁定胜局？',
        from:'爱云选公众号',
        time:'2020-12-31',
        img:[{img:p3z1},{img:p3z2},{img:p3z3},{img:p3z4},{img:p3z5,cls:'cdetbwb-img'},{img:p3z6},{img:p3z7},{img:p3z8}]
    },
    4:{
        title:'爱云选架起关键桥梁，让新零售实现人货场最优匹配',
        from:'爱云选公众号',
        time:'2021-01-08',
        img:[{img:p4z1},{img:p4z2},{img:p4z3},{img:p4z4,cls:'cdetbwb-img'},{img:p4z5},{img:p4z6},{img:p4z7},{img:p4z8}]
    },
    5:{
        title:'蔬菜直通车难进小区？爱云选专业选址服务，为运营商开启绿色通道',
        from:'爱云选公众号',
        time:'2021-01-15',
        img:[{img:p5z1},{img:p5z2},{img:p5z3,cls:'cdetbwb-img'},{img:p5z4},{img:p5z5},{img:p5z6},{img:p5z7}]
    }
}