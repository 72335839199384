import React from "react";
import { Pagination, Autoplay, EffectFade } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { connect } from "react-redux";
import store from "@/redux";

import "./index.scss";
import "swiper/css";
import "swiper/css/effect-fade";
// import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";

function SwiperCon() {
  const copywriting = [
    {
      title: "爱云选 公用空间智慧运营平台",
      content:
        "爱云选平台链接品牌方和场地方，通过数字化，智能化，标准化，交付投放更多的设备，实现空间价值最大化，致力于成为国内领先的公用空间智慧运营平台。",
    },
    {
      title: "通信基站",
      content: "中国通信基础设施规划与选址领域卓越的服务供应商",
    },
    {
      title: "换电柜",
      content: "",
    },
    {
      title: "电动自行车充电桩",
      content: "",
    },
    {
      title: "汽车充电桩",
      content: "",
    },
    {
      title: "自动售货机",
      content: "",
    },
  ];
  return (
    <div className="swiperWrap">
      <div className="swiperFixBox">
        <div>为客户发现有价值的空间</div>
      </div>
      <Swiper
        // className="swiper-no-swiping"
        modules={[Pagination, Autoplay, EffectFade]}
        slidesPerView={1}
        // effect="fade"
        navigation
        // onSwiper={(swiper) => console.log(swiper)}
        // onSlideChange={() => console.log("slide change")}
        // pagination={{ clickable: true }}
        pagination={{
          bulletClass: "swiper-pagination-bullet pages",
          bulletActiveClass: "swiper-pagination-bullet-active curPages",
          clickable: true,
          // el: ".swiper-pagination",
          // type: "custom",
          //   renderCustom: function (swiper, current, total) {
          //     return current + " of " + total;
          //   },
        }}
        loop={true}
        autoplay={{
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        }}
      >
        {/* <SwiperSlide>
          <div className="item">
            <div>fdajkfaslj </div>
            <img
              src={require("@i/banner1.png")}
              onLoad={() => {
                store.dispatch({ type: "ImgLoad", data: true });
              }}
            />
          </div>
        </SwiperSlide> */}
        {[1, 2, 3, 4, 5, 6].map((x) => {
          return (
            <SwiperSlide key={x}>
              <div className="txtWrap">
                <div className="txtBox">
                  <div className="title">{copywriting[x - 1].title}</div>
                  <div className="content">{copywriting[x - 1].content}</div>
                </div>
              </div>
              {x == 1 && (
                <img
                  src={require("@i/banner1.png")}
                  onLoad={() => {
                    store.dispatch({ type: "ImgLoad", data: true });
                  }}
                />
              )}
              {x > 1 && <img src={require("@i/banner" + x + ".png")} />}
            </SwiperSlide>
          );
        })}
        {/* <SwiperSlide>
          <img src={require("@i/banner2.png")} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={require("@i/banner3.png")} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={require("@i/banner4.png")} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={require("@i/banner5.png")} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={require("@i/banner6.png")} />
        </SwiperSlide> */}
      </Swiper>
      {/* <div className="swiper-pagination">
        <div></div>
        <div></div>
        <div></div>
      </div> */}
    </div>
  );
}
export default connect()(SwiperCon);
