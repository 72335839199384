import { Link, useNavigate } from "react-router-dom";

const LinkTo = (props) => {
  let navigate = useNavigate();
  return (
    <div onClick={() => navigate("/estdet", { state: { id: props.id } })}>
      {/* <Link to={{ path: "/estdet", state: { id: props.id } }} target="_blank"> */}
      {props.children}
      {/* </Link> */}
    </div>
  );
};

export default LinkTo;
